import React from 'react';
// Components
import Upload from '../toolbox/Upload';
import CustomText from '../toolbox/InputText';

const Inputs = ({
  checked,
  setchecked,
  handleChange,
  uploadedImage,
  setUploadedImage,
  userName,
  setUsername,
  fontFamily,
  setFontFamily,
  bgColor,
  setBgColor,
  fontColor,
  setFontColor,
  alignment,
  handleAlignment,
  align,
  setHeight,
  setWidth,
}) => (
  <div>
  <Upload
      uploadedImage={uploadedImage}
      setUploadedImage={setUploadedImage}
      setHeight={setHeight}
      setWidth={setWidth}
    />
    <CustomText
      checked={checked}
      setchecked={setchecked}
      handleChange={handleChange}
      userName={userName}
      setUsername={setUsername}
      fontFamily={fontFamily}
      setFontFamily={setFontFamily}
      bgColor={bgColor}
      setBgColor={setBgColor}
      fontColor={fontColor}
      setFontColor={setFontColor}
      alignment={alignment}
      handleAlignment={handleAlignment}
      align={align}
    />
  </div>
);

export default Inputs;
