import React from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const Container1 = styled.div`
  ${tw`
 mt-3
 py-2
 rounded-lg
 relative
`}
`;

const Section1 = styled.div`
  ${tw`
    flex
    gap-4
  `}
`;

const Heading1 = styled.h1`
  ${tw`
  pl-4
  text-left
  mt-2
  text-color-bright
 `}
  font-size: 1.8rem;
  font-family: Refrigerator Deluxe extra bold

`;

const Description = styled.div`
  ${tw`
  text-white
  w-3/4
  text-sm
  font-normal
  col-span-2
  py-2
  pl-8
  text-left
  mb-2
  leading-4
  `}
`;

const ButtonContainer = styled.span`
  ${tw`
    pl-0
  `}
`;

const Button = styled.button`
  ${tw`
    font-normal
    mb-2
    rounded-full
    h-12 w-12
    justify-center
    bg-color-bright
    border-none
    outline-none
  `}
`;
const Step = styled.img`
  ${tw`
   absolute
    h-24
    top-0
    left-0
    opacity-25
    `};
  filter: brightness(0) invert(1);
`;
const Upload = ({ setUploadedImage, setHeight, setWidth }) => (
  <Container1>
    <Step src='https://mobicheckin-assets.s3.eu-west-1.amazonaws.com/uploads/events/63ff5b42976ea6006b695226/website/assets-folder64ccf030de927e06e4296f30/1_btnq7l_832675b0-07b0-4475-8fea-afd5404929bd.png' />
    <Heading1>CHARGEZ VOTRE PHOTO</Heading1>
    <Section1>
      <Description>Pour obtenir le meilleur résultat, chargez une photo avec un angle large et choisissez le cadre le mieux adapté</Description>
      <div>
        <ButtonContainer>
          <Button>
            <label htmlFor='contained-button-file'>
              <FontAwesomeIcon icon={faUpload} style={{ color: '#000', fontSize: '23px' }} />
            </label>
          </Button>
        </ButtonContainer>

        <input
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
          id='contained-button-file'
          onChange={(e) => {
            if (e.target.files.length > 0) {
              setUploadedImage(URL.createObjectURL(e.target.files[0]));
              const i = new Image();
              i.onload = function () {
                const imageDimensions = [
                  {
                    height: i.height,
                    width: i.width,
                  },
                ];
                setHeight(imageDimensions[0].height);
                setWidth(imageDimensions[0].width);
              };

              i.src = URL.createObjectURL(e.target.files[0]);
            }
          }}
        />
      </div>
    </Section1>
  </Container1>
);

export default Upload;
